<template>
  <div class="login-container">
    <div class="top-image">
      <img src="../assets/topNavigation.png" alt="" width="378" height="61" />
    </div>
    <div class="box-info">
      <div class="box-left"></div>
      <div class="box-right" :style="{ height: loginFlag ? '' : '600px' }" v-loading="!loginFlag" element-loading-text="正在跳转中">
        <div v-show="loginFlag">
          <span>企业用户（管理端）登录</span>
          <el-form :model="userForm" :rules="rules" ref="loginForm" class="loginForm" :show-message="false">
            <el-form-item label="账号" prop="username">
              <el-input type="text" v-model="userForm.username" placeholder="请输入手机号/邮箱" class="form-input"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <div>
                <el-input type="password" placeholder="请输入密码" v-model="userForm.password" @keydown.enter.native="submitForm" class="form-input">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item><el-checkbox v-model="checked">记住我</el-checkbox></el-form-item>
            <el-form-item>
              <el-button class="btn" @click="adminLogin" :loading="loading">
                登录
              </el-button>
            </el-form-item>
            <el-form-item v-if="certificate && explorerInfo.includes('chrome')">
              <div style="text-align: right">
                <span>chrome请点击 <span style="color: rgba(97, 114, 241, 1); cursor: pointer" @click="openUrl">&lt;证书认证&gt;</span></span>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <p>Copyright©2020-2021 源图数字化人才管理平台 ALL RIGHTS RESERVED.</p>
  </div>
</template>

<script>
import { saveAuthInfo } from '@api/index'
import userApi from '@api/user'
import { mapActions } from 'vuex'
import GetBackPassword from './GetBackPassword'
import config from '../config/config'
import router from '../router'
import { doEncrypt } from '@util/util'

export default {
  components: { GetBackPassword },
  data() {
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}')
    const validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      } else {
        let regEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
        if (regEmail.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的邮箱'))
        }
      }
    }
    const validChinesePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      checked: false,
      loginFlag: true,
      currentTab: 'admin',
      userForm: {
        username: userInfo.remember ? userInfo.username : '',
        password: userInfo.remember ? userInfo.password : '',
        rememberPassword: userInfo.remember || false
      },
      rules: {
        username: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      },
      userRegistered: {
        name: '',
        address: '',
        phone: '',
        email: '',
        code: '',
        description: ''
      },
      registeredRule: {
        phone: [
          { required: true, message: '请输入手机号' },
          { type: 'string', len: 11, message: '手机号长度为11' },
          {
            type: 'integer',
            message: '手机号为数字',
            transform: value => +value
          },
          { validator: validChinesePhone }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { type: 'string', len: 6, message: '验证码长度为6' },
          {
            type: 'integer',
            message: '手机号为数字',
            transform: value => +value
          }
        ],
        address: [{ required: true, message: '公司地址不可为空' }],
        name: [{ required: true, message: '公司名称不可为空' }],
        email: [{ required: true, message: '请输入邮箱' }, { validator: validatePass }]
      },
      loading: false,
      usernameJudge: false,
      passwordJudge: false,
      disableJudge: true,
      showPassword: false,
      disableRegisteredJudge: false,
      registeredIsOk: false,
      isOk: false,
      acceptAgreement: true,
      chooseType: 'login',
      loginOrGetBackPsw: 'loginAndRegistered',
      fetchedCode: false,
      secondsToRefetch: 60,
      nameJudge: false,
      addressJudge: false,
      phoneJudge: false,
      emailJudge: false,
      codeJudge: false,
      certificate: window.g.certificate,
      explorerInfo: '',
      isShns: false
    }
  },
  computed: {
    isUploadU() {
      return window.uploadUrl
    }
  },
  watch: {
    acceptAgreement(val) {
      if (val && window.uploadUrl) {
        // 银行认证证书
        window.open(window.certifUrl)
      }
    },
    'userForm.username'(val) {
      this.usernameJudge = false
      if (val.length > 0) {
        this.usernameJudge = true
        this.judgeButton()
      } else {
        this.usernameJudge = false
        this.judgeButton()
      }
    },
    'userForm.password'(val) {
      this.passwordJudge = false
      if (val.length > 0) {
        this.passwordJudge = true
        this.judgeButton()
      } else {
        this.passwordJudge = false
        this.judgeButton()
      }
    },
    'userRegistered.name'(val) {
      this.nameJudge = false
      if (val.length > 0) {
        this.nameJudge = true
        this.jugeTrueOrFalse()
      } else {
        this.nameJudge = false
        this.jugeTrueOrFalse()
      }
    },
    'userRegistered.code'(val) {
      this.codeJudge = false
      if (val.length > 0) {
        this.codeJudge = true
        this.jugeTrueOrFalse()
      } else {
        this.codeJudge = false
        this.jugeTrueOrFalse()
      }
    },
    'userRegistered.phone'(val) {
      this.phoneJudge = false
      if (val.length > 0) {
        this.jugeTrueOrFalse()
        if (/^1([3456789])\d{9}$/.test(val)) {
          this.phoneJudge = true
        }
      } else {
        this.phoneJudge = false
        this.jugeTrueOrFalse()
      }
    },
    'userRegistered.email'(val) {
      this.emailJudge = false
      if (val.length > 0) {
        this.emailJudge = true
        this.jugeTrueOrFalse()
      } else {
        this.emailJudge = false
        this.jugeTrueOrFalse()
      }
    },
    'userRegistered.address'(val) {
      this.addressJudge = false
      if (val.length !== 0) {
        this.addressJudge = true
        this.jugeTrueOrFalse()
      } else {
        this.addressJudge = false
        this.jugeTrueOrFalse()
      }
    }
  },
  created() {
    if (window.location.href.search('sign') > 0) {
      let userInfo = this.getCaption(decodeURIComponent(window.location.href), '=')
      this.loginFlag = false
      this.getStr(window.atob(userInfo), '&')
    }
    this.judgeButton()
    this.jugeTrueOrFalse()
    this.keyupSubmit()
    this.explorerInfo = navigator.userAgent.toLowerCase()
    this.isShns = window.g.isShns
    if (window.localStorage.getItem('checked')) {
      this.userForm.username = window.localStorage.getItem('username')
    }
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    openUrl() {
      window.open(window.certifUrl)
    },
    getCaption(obj, sign) {
      let index = obj.indexOf(`${sign}`)
      obj = obj.substring(index + 1, obj.length)
      return obj
    },
    getStr(string, sign) {
      const signs = string.split(sign)
      this.userForm.username = window.atob(signs[0])
      this.userForm.password = window.atob(signs[1])
      this.$nextTick(() => {
        this.login(this.userForm.username, this.userForm.password).then(() => {
          if (this.checked) {
            window.localStorage.setItem('checked', 'true') //
            window.localStorage.setItem('username', this.userForm.username)
          } else {
            window.localStorage.removeItem('checked') //
            window.localStorage.removeItem('username')
          }
          this.$router.push('/manage')
        })
      })
    },
    keyupSubmit() {
      document.onkeydown = e => {
        let _key = window.event.keyCode
        if (_key === 13) {
          this.adminLogin()
        }
      }
    },
    submitRegister(formRef) {
      this.$refs[formRef].validate(result => {
        if (result !== false) {
          userApi.companyRegister(this.userRegistered).then(data => {
            if (data.res === true) {
              this.$message.info('需要等待管理人员审核通过,5秒后返回首页')
              setTimeout(() => {
                this.$router.push({ path: '/home' })
              }, 5000)
            }
          })
        } else {
          this.$message.error('请正确输入信息', 1500)
        }
      })
    },
    getCode(formRef) {
      this.$refs[formRef].validateField('phone', err => {
        if (!err) {
          userApi.getCodes(this.userRegistered.phone).then(data => {
            if (data.res === true) {
              this.fetchedCode = true
              this.$message.success('验证码已经发送')
              const interval = setInterval(() => {
                if (this.secondsToRefetch === 0) {
                  this.fetchedCode = false
                  this.secondsToRefetch = 60
                  clearInterval(interval)
                } else {
                  this.secondsToRefetch--
                }
              }, 1000)
            } else {
              this.$message.error('手机号异常，请稍好重试')
            }
          })
        }
      })
    },
    changePageType() {
      this.loginOrGetBackPsw = 'loginAndRegistered'
    },
    jugeTrueOrFalse() {
      if (this.nameJudge && this.addressJudge && this.phoneJudge && this.emailJudge && this.codeJudge) {
        this.registeredIsOk = true
        this.disableRegisteredJudge = false
      } else {
        this.registeredIsOk = false
        this.disableRegisteredJudge = true
      }
    },
    judgeButton() {
      if ((this.usernameJudge && this.passwordJudge) || (this.userForm.username.length > 0 && this.userForm.password.length > 0)) {
        this.isOk = true
        this.disableJudge = false
      } else {
        this.isOk = false
        this.disableJudge = true
      }
    },
    redirect() {
      //如果需要重新跳转到登录前界面
      if (this.$router.lastPath) {
        this.$router.push(this.$router.lastPath)
        return true
      }

      return false
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    adminLogin() {
      this.$refs['loginForm'].validate(async valid => {
        if (valid) {
          this.login(doEncrypt(this.userForm.username), doEncrypt(this.userForm.password)).then(() => {
            if (this.checked) {
              window.localStorage.setItem('checked', 'true') //
              window.localStorage.setItem('username', this.userForm.username)
            } else {
              window.localStorage.removeItem('checked') //
              window.localStorage.removeItem('username')
            }
            let url = localStorage.getItem('login-success-to-url')
            this.$router.push(url || '/manage')
            localStorage.removeItem('login-success-to-url')
          })
        }
      })
      // else {
      //   if (window.uploadUrl) {
      //     return this.message('请先勾选证书认证', 'warning')
      //   }
      //   this.$message.info('请勾选服务协议')
      // }
    },
    userLogin() {
      this.login().then(() => {
        // TODO 跳转到用户界面
        this.redirect() || this.$router.push('/')
      })
    },

    login(username, password) {
      this.loading = true
      return userApi
        .getAccessToken(username, password)
        .then(res => {
          if (res.error) {
            this.$message.error(res.message)
            return Promise.reject(res)
          }

          //保存登录token，供其他接口使用
          saveAuthInfo(res)

          localStorage.setItem(
            'user',
            this.userForm.rememberPassword
              ? JSON.stringify({
                  remember: true,
                  username: this.userForm.username,
                  password: this.userForm.password
                })
              : ''
          )
          // this.getUserInfo()

          this.$message.success('登录成功')
          document.onkeydown = null
          return res
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
@width: 1.8;
@height: 1.6;
.login-container {
  background: #ffffff;
  height: 100%;
  overflow-y: hidden;
  .top-image {
    text-align: center;
    padding: calc(102px / @height) 0 calc(52px / @height);
  }
  .box-info {
    width: 1060px;
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    opacity: 1;
    border-radius: 12px;
    margin: 0 auto calc(113px / @height);
    display: flex;
    overflow: hidden;
    .box-left {
      width: 526px;
      background: url('../assets/loginPicture.png') center no-repeat;
    }
    .box-right {
      width: 534px;
      padding: calc(107px / @height) 52px calc(105px / @width) 56px;
      span {
        font-size: 32px;
        font-weight: bold;
        line-height: 45px;
        color: #000000;
        margin-bottom: 19px;
        display: block;
        text-align: left;
      }
      .btn {
        width: 426px;
        height: 60px;
        background: linear-gradient(90deg, #3485ff 0%, #5f5eff 100%);
        box-shadow: 0 6px 8px rgba(34, 55, 177, 0.3);
        border-radius: 6px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        ::v-deep span {
          font-size: 26px;
          font-weight: bold;
          line-height: 37px;
          color: #ffffff;
          letter-spacing: 8px;
        }
      }
    }
    .form-input {
      ::v-deep .el-input__inner {
        height: 60px;
        background-color: #eff2f4;
      }
    }
    ::v-deep .el-form {
      .el-form-item {
        &:nth-child(1) {
          margin-bottom: 30px;
        }
        &:nth-child(2) {
          margin-bottom: 12px;
        }
        &:nth-child(3) {
          margin-bottom: 30px;
        }
        .el-form-item__content {
          text-align: left;
        }
      }
      .el-form-item__label {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #000000;
        margin-bottom: 15px;
        &:before {
          content: none;
        }
      }
    }
    ::v-deep .el-checkbox__inner {
      width: 21px;
      height: 21px;
    }
    ::v-deep .el-checkbox__label {
      padding-left: 16px;
      font-size: 20px;
      line-height: 27px;
      color: #000000;
    }
    ::v-deep .el-checkbox {
      font-size: 20px;
    }
    ::v-deep .el-checkbox__inner::after {
      height: 11px;
      left: 5px;
      width: 7px;
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }
}
@media screen and (max-height: 841px) {
  .login-container {
    zoom: 0.8;
  }
}
</style>
